//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'CompanyInfoCard',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    w: {
      type: String,
      default: '538px',
    },
  },
  computed: {
    ...mapState({
      base_user: state => state.user.user.base_user,
    }),
    styles() {
      return {
        ...(this.w && { maxWidth: this.w }),
      }
    },
  },

  methods: {
    onClick(value) {
      this.$emit('click:edit', value)
    },
    getNames(k) {
      let name = ''
      switch (k) {
      case 'companyName':
        name = this.$t('companyName')
        break
      case 'nip':
        name = this.$t('nip')
        break
      case 'email':
        name = this.$t('emailAddress')
        break
      case 'phone':
        name = this.$t('phoneNumber')
        break
      case 'address':
        name = this.$t('address')
        break
      }
      return name
    },
  },
}
