//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RoomMiniatureItem',
  components: {
    MediaFiles: () => import('~/components/UI/MediaFiles.vue'),
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
}
