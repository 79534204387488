//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from 'vuex'
import HeaderActions from './components/HeaderActions.vue'
import HeaderNavbar from './components/HeaderNavbar.vue'
import Logo from '~/components/UI/Logo.vue'
import {IS_DESKTOP, IS_LAPTOP} from '~/store/device/type.device'
import Burger from '~/components/UI/Burger.vue'
// import Search from '~/components/UI/Search/Search.vue'
import SearchWrapper from '~/components/UI/Search/SearchWrapper.vue'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import RectSkeleton from '~/components/skeleton/rect'
import getDeviceType from '~/mixins/getDeviceType'

export default {
  name: 'Header',
  components: {
    SkeletonWrapper,
    Burger,
    Logo,
    SearchWrapper,
    HeaderActions,
    HeaderNavbar,
    RectSkeleton,
    LoginModal: () =>
      import('~/components/modules/ModalWindows/Auth/LoginModal.vue'),
  },
  props: {
    disableNavbar: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [getDeviceType],
  data() {
    return {
      activeMenu: false,
      onSearch: false,
      search: '',
      loading: true,
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
  },
  mounted() {
    this.$root.$on('handleAuthModal', this.onOpenModal)
    setTimeout(() => {
      this.loading = false
    }, 35)
  },
  beforeDestroy() {
    this.$root.$off('handleAuthModal')
  },
  methods: {
    onOpenModal(type) {
      this.$refs.authModal.handleClick(type)
    },
    handleBurger(value) {
      if (this.disableNavbar) {
        this.$emit('click:burger')
        return
      }
      this.activeMenu = value
    },
  },
}
