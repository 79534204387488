//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CategoryMiniatureItem from '~/components/modules/Miniatures/CategoryMiniatureItem.vue'
export default {
  name: 'HeaderDropdownMenu',
  components: { CategoryMiniatureItem },
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      hide: true,
      hiddenMenu: false,
    }
  },
  computed: {
    miniature() {
      return {
        name: this.item.name,
        src: this.item.file_preview_url || '',
      }
    },
  },
  methods: {
    openMenuItems() {
      this.hide = !this.hide
    },
    hideMenu(items) {
      if(items > 7) {
        this.hiddenMenu = true
      }
      return this.hiddenMenu
    },
  },
}
