//
//
//
//
//
//
//
//
//
//

import MediaFiles from '~/components/UI/MediaFiles.vue'
export default {
  name: 'CategoryMiniatureItem',
  components: { MediaFiles },
  props: {
    item: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
}
