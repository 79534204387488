//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'CompanyDeliveryAddressCard',
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState({
      base_user: state => state.user.user.base_user,
    })
  },
  methods: {
    onClick(value) {
      this.$emit('click:edit', 'Edit', value)
    },
  },
}
