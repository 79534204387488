//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaFiles from '~/components/UI/MediaFiles.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'
export default {
  name: 'ProductMiniatureItem',
  components: {
    MediaFiles,
    ProductPrice,
  },
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  computed: {
    // hasPrice() {
    //   if (!Object.keys(this.item).length) return false
    //   if (Array.isArray(this.item.price_type)) return false
    //   if (!this.item.price_type) return false
    //   return !!Number(this.item.price_type.netto)
    // },
    price() {
      if (!this.hasPrice) {
        return {
          price: false,
          discountPrice: false,
          discount: '0',
        }
      }
      const price = Number(this.item.price_type.netto)
      const discountPrice = Number(this.item.price_type.discount_netto)
      const discount = Math.ceil((discountPrice * 100) / price)
      return {
        price: `${price} zl`,
        discountPrice: discountPrice && `${discountPrice} zl`,
        discount: `-${discount} %`,
      }
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
