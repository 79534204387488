//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Input from '~/components/UI/Input.vue'
export default {
  components: { Input },
  data() {
    return {
      search: '',
    }
  },

  methods: {
    onInput(value) {
      this.search = value
    },

    onClick() {},

    onClear() {},
  },
}
