//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {HAS_TOKEN} from '~/store/auth/types.auth'
import {IS_DESKTOP, IS_LAPTOP, IS_MOBILE} from '~/store/device/type.device'
import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import SkeletonRect from '~/components/skeleton/rect'
export default {
  name: 'HeaderNavbar',
  components: {
    SkeletonWrapper,
    SkeletonRect,
    HeaderPopoverAction: () => import('./HeaderPopoverAction.vue'),
    PopoverDefault: () =>
      import('~/components/modules/PopoverBlocks/PopoverDefault.vue'),
    PopoverUserMenu: () =>
      import('~/components/modules/PopoverBlocks/PopoverUserMenu.vue'),
    HeaderDropdownMenu: () => import('./HeaderDropdownMenu.vue'),
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      links: [],
      loading: true,
      isSelect: false,
      selectNav: {},
      prevuesSelectMenuItem: [],
      selectMenuItem: null,
      visibleScrollBtnLeft: false,
      visibleScrollBtnRight: false,
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
    isDesktop() {
      return this.$store.getters[`device/${IS_DESKTOP}`]
    },
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  watch: {
    $route (){
      this.isSelect = false;
    }
  },
  async mounted() {
    await this.fetchRouting()
    this.initCustomScroll()
    window.addEventListener('resize', this.initCustomScroll)
  },
  methods: {
    fetchRouting() {
      return this.$request.get('/menu/header').then(({ data }) => {
        const array = data.map((i) => ({
          ...i,
          file: i.file_url ? `${this.$config.baseURL}/files/${i.file_url}` : null,
        }))
        const links = []
        array.forEach((item, i, self) => {
          const parent = self.find((p) => p.id === item.parent_id)
          if (parent) {
            parent.active = false
            parent.children ? parent.children.push(item) : (parent.children = [item])
          } else {
            links.push(item)
          }
        })
        this.links = links
        this.loading = false
      })
    },

    onClosePopover() {
      this.$refs.popover.$refs.popover.onClose()
    },

    onCloseMenu() {
      this.prevuesSelectMenuItem = []
      this.selectMenuItem = null
      this.$emit('close:menu', false)
    },

    onSelectOver() {
      if (Object.keys(this.selectNav).length) {
        this.selectNav.active = false
      }
      this.isSelect = false
    },

    onSelectNav(i) {
      if (Object.keys(this.selectNav).length) {
        this.selectNav.active = false
      }
      this.selectNav = this.links[i] || {}
      this.selectNav.active = true
      this.isSelect = true
    },
    onSelectMenuItem(value) {
      this.prevuesSelectMenuItem.push(this.selectMenuItem)
      this.selectMenuItem = value
    },
    onSelectBeforeMenuItem() {
      this.selectMenuItem =
        this.prevuesSelectMenuItem[this.prevuesSelectMenuItem.length - 1]
      this.prevuesSelectMenuItem.pop()
    },
    initCustomScroll() {
      const widthNavbarWrapper = document.getElementById('navbar-wrapper').offsetWidth
      const widthNavbarList = document.getElementById('navbar-list').offsetWidth
      if(widthNavbarList > widthNavbarWrapper) {
        this.visibleScrollBtnRight = true
      } else {
        this.visibleScrollBtnRight = false
      }
    },
    clickScrollBtnLeft() {
      const navbar = document.getElementById('navbar-wrapper')
      navbar.scrollLeft -= 100
      if(navbar.scrollLeft === 0) {
        this.visibleScrollBtnRight = true
        this.visibleScrollBtnLeft = false
      }
    },
    clickScrollBtnRight() {
      const navbar = document.getElementById('navbar-wrapper')
      navbar.scrollLeft += 100
      if(navbar.scrollWidth - navbar.scrollLeft === navbar.offsetWidth) {
        this.visibleScrollBtnRight = false
        this.visibleScrollBtnLeft = true
      }
    },
  },
}
