//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    loyaltyPoints: {
      type: Number,
      default: null,
    },
    loyaltyPointsDiscount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    areThereAnyLoyaltyPoints() {
      return this.$props.loyaltyPoints && this.$props.loyaltyPointsDiscount
    },
  },
  methods: {
    async applyLoyaltyProgram() {
      try {
        await this.$api.cart.applyLoyaltyProgram()
        this.$emit('change')
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteLoyaltyProgram() {
      try {
        await this.$api.cart.deleteLoyaltyProgram()
        this.$emit('change')
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
