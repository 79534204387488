//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      promocode: '',
      selectedPromocode: false,
      hideSelectPromocode: false,
      message: '',
    }
  },
  computed: {},
  watch: {
    value(val) {
      if (val !== this.promocode) {
        this.promocode = val
      }
    },
  },
  created() {
    if (this.$props.value) {
      this.promocode = this.$props.value
      this.selectedPromocode = !!this.$props.value
      this.hideSelectPromocode = !!this.$props.value
    }
  },
  methods: {
    makeToast(variant = null) {
      const h = this.$createElement
      this.message.forEach(message => {
        const vNodesMsg = h(
          'p',
          [ message ]
        )

        this.$bvToast.toast([vNodesMsg], {
          autoHideDelay: 3000,
          variant,
          solid: true,
        })
      })
    },
    hideMenuSelectPromocode() {
      this.hideSelectPromocode = !this.hideSelectPromocode
    },
    async applyPromocode() {
      if(this.promocode === '') return;
      const payload = {
        promo_code: this.promocode,
      }
      try {
        this.selectedPromocode = this.promocode
        await this.$api.cart.applyPromocode(payload)
        this.$emit('change')
      } catch (error) {
        this.message = Object.values(error.response.data.errors);
        this.makeToast('danger')
      }
    },
    async deletePromocode() {
      try {
        this.promocode = ''
        this.selectedPromocode = false
        await this.$api.cart.deletePromocode()
        this.$emit('change')
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
